import React, { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Card, Drawer, Input, Modal, Radio, Select, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash.debounce'
import { useUsers } from './hooks/useUsers'
import UsersTable from './UsersTable'
import { CreateUserForm } from './forms'
import { ROLES } from '../../constants'
import { UserMembership } from '../../types'
import { useMemberships } from '../memberships/hooks'
import { UserDetailsPage } from './UserDetailsPage'
import { useParams, useSearchParams } from 'react-router-dom'

export const UsersPage: React.FC = () => {
  const { t } = useTranslation()
  const { id } = useParams<{ id?: string }>()
  const [searchParams, setSearchParams] = useSearchParams()
  const searchTerm = searchParams.get('searchTerm') || ''
  const userMembershipStatus = (searchParams.get('userMembershipStatus') ||
    'all') as UserMembership['status']
  const membershipId = searchParams.get('membershipId') || 'all'
  const { users, total, loading, refetch } = useUsers({
    role: ROLES.client.value,
    searchTerm,
    userMembershipStatus,
    membershipId,
  })
  const [createModalVisible, toggleCreateModal] = useState(false)
  const { memberships } = useMemberships()

  const handleCreateComplete = () => {
    toggleCreateModal(false)
    refetch()
  }

  const handleSearch = (term: string) => {
    setSearchParams({ searchTerm: term, membershipId, userMembershipStatus })
  }

  const onChange = (page: number) => {
    refetch(page)
  }

  const handleUserMembershipStatusChange = (e: any) => {
    setSearchParams({
      membershipId,
      searchTerm: searchTerm as string,
      userMembershipStatus: e.target.value,
    })
  }

  const handleMembershipChange = (value: string) => {
    if (!value) {
      setSearchParams({
        userMembershipStatus,
        searchTerm: searchTerm as string,
        membershipId: 'all',
      })
      return
    }
    setSearchParams({
      userMembershipStatus,
      searchTerm: searchTerm as string,
      membershipId: value,
    })
  }

  const membershipOptions = memberships
    ? [
        {
          label: 'All memberships',
          value: 'all',
        },
        ...memberships.map((membership) => ({
          label: membership.name,
          value: membership.id,
        })),
      ]
    : [
        {
          label: 'All memberships',
          value: 'all',
        },
      ]

  return (
    <>
      <Card
        title={`Users (${total})`}
        extra={
          <Button
            onClick={() => toggleCreateModal(true)}
            icon={<PlusOutlined />}
          >
            {t('users.create', 'Create user')}
          </Button>
        }
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Space direction="horizontal">
            <Input.Search
              placeholder="Search"
              onSearch={handleSearch}
              onChange={debounce((e) => handleSearch(e.target.value), 700)}
              style={{ width: 200 }}
              defaultValue={searchTerm || ''}
            />
            <Radio.Group
              value={userMembershipStatus}
              onChange={handleUserMembershipStatusChange}
            >
              <Radio.Button value="all">All</Radio.Button>
              <Radio.Button value="active">
                {t('memberships.statuses.active')}
              </Radio.Button>
              <Radio.Button value="expired">
                {t('memberships.statuses.expired')}
              </Radio.Button>
              <Radio.Button value="expires_soon">
                {t('memberships.statuses.expiresSoon')}
              </Radio.Button>
            </Radio.Group>
            <Select
              allowClear
              options={membershipOptions}
              style={{ width: '250px' }}
              value={membershipId}
              onChange={handleMembershipChange}
            />
          </Space>
          <UsersTable
            users={users || []}
            loading={loading}
            total={total}
            onChange={onChange}
            showActions
            onDeleteUser={refetch}
          />
        </Space>
      </Card>

      <Modal
        title={t('users.create', 'Create user')}
        open={createModalVisible}
        onCancel={() => toggleCreateModal(false)}
        footer={null}
      >
        <CreateUserForm
          onComplete={handleCreateComplete}
          role={ROLES.client.value}
          showPhone
          showPassword={false}
        />
      </Modal>

      <Drawer
        width={500}
        onClose={() => history.back()}
        open={!!id}
        bodyStyle={{ padding: '8px' }}
        destroyOnClose
      >
        <UserDetailsPage onUpdate={refetch} />
      </Drawer>
    </>
  )
}
