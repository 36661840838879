import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AppError, ChildrenProps } from '../../types'
import { AuthApi, UsersApi } from '../api'
import { ApiErrorResponse, localizeApiErrors } from '../api/ApiErrors'
import { apiClient } from '../api/ApiClient'
import { AuthState } from './types'
import { ROLES } from '../../constants'

export const AuthContext = React.createContext<AuthState>({
  handleSignup: () => Promise.resolve(),
  handleLogin: () => Promise.resolve(),
  handleLogout: () => Promise.resolve(),
  handleInitiateForgotPassword: () => Promise.resolve(),
  loggedIn: false,
  loading: false,
  refreshLoading: true,
})

export const AuthProvider: React.FC<ChildrenProps> = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [user, setUser] = useState<AuthState['user']>()
  const [loggedIn, setLoggedIn] = useState(false)
  const [loading, setLoading] = useState(false)
  const [refreshLoading, setRefreshLoading] = useState(true)
  const [errors, setErrors] = useState<AppError[]>()
  const [refreshError, setRefreshError] = useState(false)

  const isSuperAdmin = user?.role === ROLES.superAdmin.value

  const handleError = (response: ApiErrorResponse) =>
    localizeApiErrors(response, setErrors)

  const handleSignup: AuthState['handleSignup'] = async (params) => {
    setLoading(true)
    setErrors(undefined)
    try {
      const response = await AuthApi.signup(params)
      localStorage.setItem('authToken', response.authToken)
      localStorage.setItem('refreshToken', response.refreshToken)
      apiClient.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${response.authToken}`
      await getCurrentUser()
      navigate('/dashboard')
    } catch (err: any) {
      setLoading(false)
      const response = err.response?.data || { errors: [{ type: 'ERROR' }] }
      handleError(response)
    }
  }

  const handleLogin: AuthState['handleLogin'] = async (email, password) => {
    setLoading(true)
    setErrors(undefined)
    try {
      const response = await AuthApi.login(email, password)
      localStorage.setItem('authToken', response.authToken)
      localStorage.setItem('refreshToken', response.refreshToken)
      apiClient.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${response.authToken}`
      await getCurrentUser()
      navigate('/dashboard')
    } catch (err: any) {
      setLoading(false)
      const response = err.response?.data || { errors: [{ type: 'ERROR' }] }
      handleError(response)
    }
  }

  const resetAuthState = () => {
    setLoading(false)
    setUser(undefined)
    setLoggedIn(false)
    setErrors(undefined)
    localStorage.removeItem('authToken')
    localStorage.removeItem('refreshToken')
  }

  const getCurrentUser = async () => {
    const response = await UsersApi.getCurrentUser()
    setUser(response)
    setLoading(false)
    setLoggedIn(true)
  }

  const handleLogout: AuthState['handleLogout'] = async () => {
    try {
      await AuthApi.logout()
    } catch (err: any) {
      console.error(err)
    }
    resetAuthState()
    apiClient.defaults.headers.common['Authorization'] = null
  }

  const getTokenFromRefreshToken = useCallback(
    async (withoutLoading = false) => {
      if (refreshError) return

      const refreshToken = localStorage.getItem('refreshToken')
      if (!refreshToken || refreshToken === 'undefined') {
        setRefreshLoading(false)
        return false
      }

      if (!withoutLoading) {
        setRefreshLoading(true)
      }

      try {
        const response = await AuthApi.refreshToken(refreshToken)
        localStorage.setItem('authToken', response.authToken)
        localStorage.setItem('refreshToken', response.refreshToken)
        apiClient.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${response.authToken}`
        await getCurrentUser()
        setRefreshLoading(false)
        if (location.pathname.includes('auth')) {
          navigate('/dashboard')
        }
      } catch (err: any) {
        setRefreshLoading(false)
        setLoggedIn(false)
        apiClient.defaults.headers.common['Authorization'] = null
        setRefreshError(true)
      }
    },
    [location.pathname, navigate, refreshError]
  )

  const handleInitiateForgotPassword = async (email: string) => {
    try {
      setLoading(true)
      await AuthApi.initiateResetPassword(email)
      setLoading(false)
    } catch (err: any) {
      setLoading(false)
      const response = err.response?.data || { errors: [{ type: 'ERROR' }] }
      handleError(response)
    }
  }

  useEffect(() => {
    getTokenFromRefreshToken()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      console.log('Refreshing token...')
      getTokenFromRefreshToken(true)
    }, 1000 * 60 * 10)
    return () => clearInterval(interval)
  }, [])

  const value = {
    user,
    company: user?.company,
    loggedIn,
    handleSignup,
    handleLogin,
    handleLogout,
    handleInitiateForgotPassword,
    loading,
    refreshLoading,
    errors,
    isSuperAdmin,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
