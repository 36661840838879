import React, { useState } from 'react'
import { Input, Button, Form, DatePicker } from 'antd'
import { UsersApi } from '../../api'
import { User } from '../../../types';
import { useTranslation } from 'react-i18next';
import { AppError } from '../../types';
import { Errors } from '../../../components';
import dayjs from 'dayjs';

interface UpdateUserFormProps {
  user: User;
  onComplete: () => void;
}

interface UpdateUserFormData extends Omit<User, 'id'|'name'> {
  callingCode?: string;
}

export const UpdateUserForm: React.FC<UpdateUserFormProps> = ({onComplete, user}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<AppError[]>([]);

  const handleFinish = async (values: UpdateUserFormData) => {
    setLoading(true);
    try {
      const dobObject = dayjs(values.dob);
      await UsersApi.update(user.id, {
        user: {...values, dob: dobObject.hour(12).toISOString()}
      });
      onComplete();
    } catch(err: any) {
      setErrors([err]);
      setLoading(false);
    }
  }
  return (
    <Form<UpdateUserFormData>
      name="createUserForm"
      onFinish={handleFinish}
      layout="vertical"
    >
      <Errors errors={errors} />
      <Form.Item
        label={t('users.firstName')}
        name="firstName"
        initialValue={user.firstName}
        rules={[{ required: true, message: 'Please input user first name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('users.lastName')}
        name="lastName"
        initialValue={user.lastName}
        rules={[{ required: true, message: 'Please input user last name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('users.email')}
        name="email"
        initialValue={user.email}
        rules={[{ required: true, message: 'Please input user email' }]}
      >
        <Input disabled={true} />
      </Form.Item>

      <Form.Item
        label={t('users.phone')}
        name="phone"
        initialValue={user.phone}
        rules={[{ required: true, message: 'Please input user phone' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t("users.dob")}
        name="dob"
        rules={[
          {
            required: true,
            message: t(
              "users.form.errors.dob",
              "Please input user date of birth"
            ) as string,
          },
        ]}
        initialValue={user.dob && dayjs(user.dob)}
      >
        <DatePicker format="DD.MM.YYYY" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>
          Update
        </Button>
      </Form.Item>
  </Form>
  )
}