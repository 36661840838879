import React from 'react';
import { Layout } from "antd"
import logo from '../../assets/logo.png';
import { Spinner } from '../Spinner';

const { Content, Footer } = Layout;

export const FullScreenLoader = () => {
  return (
    <Layout className="layout" style={{ height: '100vh' }}>
      <Content style={{ 
        height: '100%',
        padding: '32px 48px',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignContent: 'center',
      }}>
        <img src={logo} alt="GymAdmin" style={{ maxWidth: '100%' }} />
        <Spinner />
      </Content>
      <Footer style={{ textAlign: 'center' }}>GymAdmin</Footer>
    </Layout>
  )
}