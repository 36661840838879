import { useEffect, useState } from 'react'
import { Component } from '../../../types'
import { ComponentsApi } from '../../api'

export const useComponents = () => {
  const [loading, setLoading] = useState(false)
  const [components, setComponents] = useState<Component[]>()

  const fetchComponents = async () => {
    setLoading(true)
    const data = await ComponentsApi.getAll()
    setComponents(data)
    setLoading(false)
  }

  useEffect(() => {
    fetchComponents()
  }, [])

  return {
    components,
    loading,
    refetch: fetchComponents,
  }
}
