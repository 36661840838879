import React from 'react'
import {
  Button,
  Popconfirm,
  Table,
  TableColumnType,
  Tag,
  notification,
} from 'antd'
import { Membership, TermsAndConditions } from '../../types'
import { useTranslation } from 'react-i18next'
import { formatDateTime } from '../helpers'
import { Link } from 'react-router-dom'
import { CheckCircleTwoTone, DeleteOutlined } from '@ant-design/icons'
import { theme } from '../../theme'
import { TermsAndConditionsApi } from '../api'

type TermsAndConditionsTableProps = {
  termsAndConditions: TermsAndConditions[]
  refetch: () => void
}

export const TermsAndConditionsTable: React.FC<
  TermsAndConditionsTableProps
> = ({ termsAndConditions, refetch }) => {
  const { t } = useTranslation()

  const handleDeleteTermsAndConditions = async (record: TermsAndConditions) => {
    await TermsAndConditionsApi.destroy(record.id)
    notification.success({
      message: t('general.deleteSuccess'),
    })
    refetch()
  }

  const columns: TableColumnType<TermsAndConditions>[] = [
    {
      title: t('general.name'),
      dataIndex: 'name',
      key: 'name',
      render: (name, item) => (
        <Link to={`/termsAndConditions/${item.id}`}>{name}</Link>
      ),
    },
    {
      title: t('termsAndConditions.signupTermsAndConditions'),
      key: 'isSignupTermsAndConditions',
      render: (_, item) =>
        item.company?.signupTermsAndConditionsId === item.id && (
          <CheckCircleTwoTone color={theme.colors.primary} />
        ),
    },
    {
      title: t('memberships.memberships'),
      dataIndex: 'memberships',
      key: 'memberships',
      render: (memberships) => (
        <>
          {memberships.map((membership: Membership) => (
            <Tag key={membership.id}>{membership.name}</Tag>
          ))}
        </>
      ),
    },
    {
      title: t('general.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt) => formatDateTime(updatedAt),
    },
    {
      title: t('general.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => formatDateTime(createdAt),
    },
    {
      key: 'actions',
      render: (_, item) => (
        <Popconfirm
          title={`${t('general.areYouSure')}`}
          onConfirm={() => handleDeleteTermsAndConditions(item)}
          okText={`${t('general.yes')}`}
          cancelText={`${t('general.no')}`}
        >
          <Button key="delete" danger icon={<DeleteOutlined />}>
            {t('actions.delete')}
          </Button>
        </Popconfirm>
      ),
    },
  ]

  return (
    <>
      <Table dataSource={termsAndConditions} columns={columns} rowKey="id" />
    </>
  )
}
