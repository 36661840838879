import { Tag } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { UserMembership } from '../../../types'
import { formatDate } from '../../helpers'

type UserMembershipStatusProps = {
  status?: UserMembership['status']
  endDate?: UserMembership['endDate']
}

export const UserMembershipStatus: React.FC<UserMembershipStatusProps> = ({
  status,
  endDate,
}) => {
  const { t } = useTranslation()

  switch (status) {
    case 'expires_soon':
      return (
        <Tag color="warning">
          {t('memberships.statuses.expiresSoon')}{' '}
          {endDate && `- ${formatDate(endDate)}`}
        </Tag>
      )
    case 'active':
      return <Tag color="success">{t('memberships.statuses.active')}</Tag>
    case 'expired':
      return (
        <Tag color="error">
          {t('memberships.statuses.expired')}{' '}
          {endDate && `- ${formatDate(endDate)}`}
        </Tag>
      )
    default:
      return <Tag>{t('memberships.statuses.inactive')}</Tag>
  }
}
