import { useEffect, useState } from "react"
import { TermsAndConditions } from "../../../types";
import { TermsAndConditionsApi } from "../../api";

export const useTermsAndConditions = () => {
  const [loading, setLoading] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState<TermsAndConditions[]>();

  const fetchTermsAndConditions = async () => {
    setLoading(true);
    const data = await TermsAndConditionsApi.getAll()
    setTermsAndConditions(data);
    setLoading(false);
  }

  useEffect(() => {
    fetchTermsAndConditions();
  }, [])

  return {
    termsAndConditions,
    loading,
    refetch: fetchTermsAndConditions
  }
}