import { DeleteOutlined } from '@ant-design/icons'
import { Card, Button, Popconfirm, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Plan } from '../../types'
import { PlansApi } from '../api'
import { PlanForm } from './forms/PlanForm'

export const PlanEditPage: React.FC = () => {
  const navigate = useNavigate()
  const { id = '' } = useParams()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [plan, setPlan] = useState<Plan>()

  const fetchPlan = async () => {
    if (id === 'new') return
    setLoading(true)
    const data = await PlansApi.get(id)
    setPlan(data)
    setLoading(false)
  }

  const handleDeleteTermsAndConditions = async (record: Plan) => {
    await PlansApi.destroy(record.id)
    notification.success({
      message: t('general.deleteSuccess'),
    })
    navigate('/plans')
  }

  useEffect(() => {
    fetchPlan()
  }, [])

  return (
    <>
      <Card
        title={t('plans.plans')}
        loading={loading}
        extra={[
          plan && (
            <Popconfirm
              title={`${t('general.areYouSure')}`}
              onConfirm={() => plan && handleDeleteTermsAndConditions(plan)}
              okText={`${t('general.yes')}`}
              cancelText={`${t('general.no')}`}
            >
              <Button key="delete" danger icon={<DeleteOutlined />}>
                {t('actions.delete')}
              </Button>
            </Popconfirm>
          ),
        ]}
      >
        <PlanForm plan={plan} />
      </Card>
    </>
  )
}
