import React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Card, Tabs } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { usePlans } from './hooks/usePlans'
import { PlansTable } from './PlansTable'
import { ComponentsPage } from './ComponentsPage'

export const PlansPage: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { plans, loading, refetch } = usePlans()

  return (
    <>
      <Tabs
        defaultActiveKey="plans"
        items={[
          {
            key: 'plans',
            label: t('plans.plans'),
            children: [
              <Card
                title={t('plans.plans')}
                extra={
                  <Button
                    onClick={() => {
                      navigate('/plans/new')
                    }}
                    icon={<PlusOutlined />}
                  >
                    {t('actions.create')}
                  </Button>
                }
                loading={loading || !plans}
              >
                <PlansTable plans={plans || []} refetch={refetch} />
              </Card>,
            ],
          },
          {
            key: 'exercises',
            label: t('plans.components.components'),
            children: [<ComponentsPage />],
          },
        ]}
      />
    </>
  )
}
