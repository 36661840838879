import React, { useState } from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Modal, notification, Space, Table, TableColumnType } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCompanies } from './hooks/useCompanies';
import { Company } from '../../types';
import { UpdateCompanyForm, CreateCompanyForm } from './forms';
import { Link } from 'react-router-dom';
import { CompaniesApi } from '../api';

export const CompaniesPage: React.FC = () => {
  const {t} = useTranslation();
  const {companies, loading, refetch} = useCompanies();
  const [createModalVisible, toggleCreateModal] = useState(false);
  const [updateModalVisible, toggleUpdateModal] = useState(false);
  const [editCompany, setEditCompany] = useState<Company>();

  const handleEditCompany = (company: Company) => () => {
    toggleUpdateModal(true);
    setEditCompany(company);
  }

  const handleUpdateComplete = () => {
    toggleUpdateModal(false);
    setEditCompany(undefined);
    refetch();
  }

  const handleCreateComplete = () => {
    toggleCreateModal(false);
    refetch();
  }

  const handleDeleteCompany = (company: Company) => async () => {
    try {
      await CompaniesApi.deleteCompany(company.id);
      notification.open({
        message: t('companies.deleteSuccess'),
        type: 'success',
      });
      refetch();
    } catch(err) {
      console.error(err);
    }
  }

  const columns: TableColumnType<Company>[] = [
    {
      title: 'Logo',
      dataIndex: 'logoUrl',
      key: 'logo',
      render: (value, record) => value ? (
        <img src={value} alt={record.name} style={{ maxHeight: '64px' }} />
      ) : <Avatar>{record.name.split(" ").map((n)=>n[0]).join("")}</Avatar>
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => (<Link to={`/companies/${record.id}`}>{value}</Link>)
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button key="edit" icon={<EditOutlined />} onClick={handleEditCompany(record)}>{t('actions.edit')}</Button>
          <Button key="destroy" icon={<DeleteOutlined />} onClick={handleDeleteCompany(record)} danger>{t('actions.delete')}</Button>
        </Space>
      )
    }
  ];

  return (
    <>
      <Card
        title="Companies"
        extra={<Button onClick={() => toggleCreateModal(true)} icon={<PlusOutlined />}>{t('companies.create', 'Create company')}</Button>}
        loading={loading || !companies}>
          <Table dataSource={companies} columns={columns} />
      </Card>

      <Modal title="Create company" open={createModalVisible} onCancel={() => toggleCreateModal(false)} footer={null}>
        <CreateCompanyForm onComplete={handleCreateComplete} />
      </Modal>

      {editCompany && <Modal title="Update company" open={updateModalVisible}  onCancel={() => toggleUpdateModal(false)} footer={null}>
        <UpdateCompanyForm company={editCompany} onComplete={handleUpdateComplete}/>
      </Modal>}
    </>
  )
}