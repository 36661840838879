import { Analytics, Company, Entry, User } from '../../types'
import { apiClient } from './ApiClient'

export const getCompanies: () => Promise<Company[]> = async () => {
  const response = await apiClient.get<Company[]>('/companies')
  return response.data
}

export const getCompany: (id: string) => Promise<Company> = async (id) => {
  const response = await apiClient.get<Company>(`/companies/${id}`)
  return response.data
}

export const createCompany: (data: FormData) => Promise<Company> = async (
  data
) => {
  const response = await apiClient.post<Company>('/companies', data)
  return response.data
}

export const updateCompany: (
  id: string,
  data: FormData
) => Promise<Company> = async (id, data) => {
  const response = await apiClient.put<Company>(`/companies/${id}`, data)
  return response.data
}

export const getUsers = async (
  id: string,
  params: { role?: string; searchTerm?: string } = {}
) => {
  const response = await apiClient.get<User[]>(`/companies/${id}/users`, {
    params,
  })
  return response.data
}

export const getActiveEntries = async (
  id: string,
  params: { role?: string; searchTerm?: string } = {}
) => {
  const response = await apiClient.get<Entry[]>(
    `/companies/${id}/active_entries`,
    {
      params,
    }
  )
  return response.data
}

export const getInvalidEntries = async (
  id: string,
  params: { role?: string; searchTerm?: string } = {}
) => {
  const response = await apiClient.get<Entry[]>(
    `/companies/${id}/invalid_entries`,
    {
      params,
    }
  )
  return response.data
}

export const deleteCompany = async (id: string) => {
  const response = await apiClient.delete<Company>(`/companies/${id}`)
  return response.data
}

export const getAnalytics = async (id: string) => {
  const response = await apiClient.get<Analytics>(`/companies/${id}/analytics`)
  return response.data
}
