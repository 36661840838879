import { Alert } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { theme } from '../theme';
import { AppError } from '../types';

const ErrorsWrap = styled.div`
  padding-bottom: ${theme.spacing.padding}
`

interface ErrorsProps {
  errors?: AppError[]
}

export const Errors: React.FC<ErrorsProps> = ({ errors }) => {
  return errors && errors.length ? (
    <ErrorsWrap>
      { 
        errors.map(error => (
            <Alert
              key={error.message}
              message={error.message}
              type="error"
              closable
            />
          )
        )
      }
    </ErrorsWrap>
  ) : null
}