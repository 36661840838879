import React from 'react'
import { Button, Popconfirm, Table, TableColumnType, notification } from 'antd'
import { Component } from '../../types'
import { useTranslation } from 'react-i18next'
import { formatDateTime } from '../helpers'
import { Link } from 'react-router-dom'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { ComponentsApi } from '../api'

type ComponentsTableProps = {
  components: Component[]
  refetch: () => void
  onEdit?: (component: Component) => void
}

export const ComponentsTable: React.FC<ComponentsTableProps> = ({
  components,
  refetch,
  onEdit,
}) => {
  const { t } = useTranslation()

  const handleDeleteComponent = async (record: Component) => {
    await ComponentsApi.destroy(record.id)
    notification.success({
      message: t('general.deleteSuccess'),
    })
    refetch()
  }

  const handleEdit = (component: Component) => {
    onEdit?.(component)
  }

  const columns: TableColumnType<Component>[] = [
    {
      title: t('general.name'),
      dataIndex: 'name',
      key: 'name',
      render: (name, item) => <Link to={`/components/${item.id}`}>{name}</Link>,
    },
    {
      title: t('general.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('plans.components.videoUrl'),
      dataIndex: 'videoUrl',
      key: 'videoUrl',
    },
    {
      title: t('general.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt) => formatDateTime(updatedAt),
    },
    {
      title: t('general.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => formatDateTime(createdAt),
    },
    {
      key: 'actions',
      render: (_, item) => (
        <Button
          key="edit"
          icon={<EditOutlined />}
          onClick={() => handleEdit(item)}
        >
          {t('actions.edit')}
        </Button>
        // <Popconfirm
        //   title={`${t('general.areYouSure')}`}
        //   onConfirm={() => handleDeleteComponent(item)}
        //   okText={`${t('general.yes')}`}
        //   cancelText={`${t('general.no')}`}
        // >
        //   <Button key="delete" danger icon={<DeleteOutlined />}>
        //     {t('actions.delete')}
        //   </Button>
        // </Popconfirm>
      ),
    },
  ]

  return (
    <>
      <Table dataSource={components} columns={columns} rowKey="id" />
    </>
  )
}
