import { useEffect, useState } from "react"
import { Company } from "../../../types";
import { CompaniesApi } from "../../api";

export const useCompanies = () => {
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState<Company[]>();

  const fetchCompanies = async () => {
    setLoading(true);
    const data = await CompaniesApi.getCompanies()
    setCompanies(data);
    setLoading(false);
  }

  useEffect(() => {
    fetchCompanies();
  }, [])

  return {
    companies,
    loading,
    refetch: fetchCompanies
  }
}