import React from 'react'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import 'antd/dist/reset.css'
import './App.css'
import {
  AuthProvider,
  DashboardPage,
  LoginPage,
  SignupPage,
  ForgotPasswordPage,
} from './modules'
import { AuthLayout, ProtectedRoute } from './components'
import enTranslation from './locales/en.json'
import { CompaniesPage, CompanyDetailsPage } from './modules/companies'
import { UsersPage } from './modules/users'
import { ConfigProvider } from 'antd'
import { MembershipsPage } from './modules/memberships'
import { PrivacyPage } from './PrivacyPage'
import { TermsAndConditionsPage } from './modules/termsAndConditions/TermsAndConditionsPage'
import { EditTermsAndConditionsPage } from './modules/termsAndConditions'
import { AnalyticsPage } from './modules/analytics'
import { PlansPage } from './modules/plans/PlansPage'
import { PlanEditPage } from './modules/plans/PlanEditPage'
import { ComponentsPage } from './modules/plans/ComponentsPage'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: enTranslation,
      },
    },
    lng: 'en', // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })

function App() {
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Montserrat',
          },
        }}
      >
        <Router>
          <AuthProvider>
            <Routes>
              <Route
                index
                element={
                  <ProtectedRoute>
                    <DashboardPage />
                  </ProtectedRoute>
                }
              />
              <Route path="privacy" element={<PrivacyPage />} />
              <Route path="auth" element={<AuthLayout />}>
                <Route path="signup" element={<SignupPage />} />
                <Route path="login" element={<LoginPage />} />
                <Route
                  path="forgot-password"
                  element={<ForgotPasswordPage />}
                />
              </Route>
              <Route
                path="dashboard"
                element={
                  <ProtectedRoute>
                    <DashboardPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="analytics"
                element={
                  <ProtectedRoute>
                    <AnalyticsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="companies"
                element={
                  <ProtectedRoute>
                    <CompaniesPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="companies/:id"
                element={
                  <ProtectedRoute>
                    <CompanyDetailsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="users"
                element={
                  <ProtectedRoute>
                    <UsersPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="users/:id"
                element={
                  <ProtectedRoute>
                    <UsersPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="memberships"
                element={
                  <ProtectedRoute>
                    <MembershipsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="termsAndConditions"
                element={
                  <ProtectedRoute>
                    <TermsAndConditionsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="termsAndConditions/:id"
                element={
                  <ProtectedRoute>
                    <EditTermsAndConditionsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="plans"
                element={
                  <ProtectedRoute>
                    <PlansPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="plans/:id"
                element={
                  <ProtectedRoute>
                    <PlanEditPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="components"
                element={
                  <ProtectedRoute>
                    <ComponentsPage />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<h1>404 Not found</h1>}></Route>
            </Routes>
          </AuthProvider>
        </Router>
      </ConfigProvider>
    </>
  )
}

export default App
