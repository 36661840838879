import React, { useMemo, useState } from 'react'
import { Button, Form, Input, Select } from 'antd'
import { MembershipsApi } from '../../api'
import { ApiErrorResponse, localizeApiErrors } from '../../api/ApiErrors'
import { useTranslation } from 'react-i18next'
import { AppError, Membership } from '../../../types'
import { Errors } from '../../../components'
import { useTermsAndConditions } from '../../termsAndConditions/hooks'

interface UpdateMembershipFormData {
  id: string
  name: string
  dailyEntryLimit?: number
  termsAndConditionsId?: string | null
}

interface UpdateMembershipFormProps {
  membership: Membership
  onComplete: () => void
}

export const UpdateMembershipForm: React.FC<UpdateMembershipFormProps> = ({
  membership,
  onComplete,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<AppError[]>([])
  const { termsAndConditions } = useTermsAndConditions()

  const termsAndConditionsOptions = useMemo(() => {
    return termsAndConditions?.map((termsAndConditions) => ({
      label: termsAndConditions.name,
      value: termsAndConditions.id,
    }))
  }, [termsAndConditions])

  const handleError = (response: ApiErrorResponse) =>
    localizeApiErrors(response, setErrors)

  const handleFinish = async (values: UpdateMembershipFormData) => {
    setLoading(true)
    try {
      await MembershipsApi.update(values.id, {
        membership: {
          ...values,
          termsAndConditionsId: values.termsAndConditionsId
            ? values.termsAndConditionsId
            : null,
        },
      })
      setLoading(false)
      onComplete()
    } catch (err: any) {
      setLoading(false)
      handleError(err.response.data)
    }
  }

  return (
    <Form<UpdateMembershipFormData>
      name="updateMembershipForm"
      onFinish={handleFinish}
      layout="vertical"
    >
      <Errors errors={errors} />

      <Form.Item name="id" initialValue={membership.id} hidden={true} />

      <Form.Item
        label={t('memberships.name')}
        name="name"
        initialValue={membership.name}
        rules={[
          {
            required: true,
            message: t('forms.errors.requiredField') as string,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={`${t('memberships.dailyEntryLimit')} (${t(
          'memberships.dailyEntryLimitHint'
        )})`}
        name="dailyEntryLimit"
        initialValue={membership.dailyEntryLimit}
      >
        <Input type="number" />
      </Form.Item>

      <Form.Item
        label={t('termsAndConditions.termsAndConditions')}
        name="termsAndConditionsId"
        initialValue={membership.termsAndConditionsId}
      >
        <Select allowClear options={termsAndConditionsOptions} />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={loading}
          loading={loading}
        >
          {t('actions.save')}
        </Button>
      </Form.Item>
    </Form>
  )
}
