import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../modules'
import { ChildrenProps } from '../types'
import { AuthenticatedLayout } from './layout'
import { FullScreenLoader } from './layout/FullScreenLoader'

export const ProtectedRoute: React.FC<ChildrenProps> = ({ children }) => {
  const { loggedIn, refreshLoading } = useAuth()

  if (refreshLoading) {
    return <FullScreenLoader />
  }

  if (!loggedIn) {
    return <Navigate to="/auth/login" replace />
  }

  return <AuthenticatedLayout>{children}</AuthenticatedLayout>
}
