import axios from "axios";

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/v1`
});

apiClient.interceptors.request.use(function (config) {
  const token = localStorage.getItem('authToken');
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  return config;
});

export { apiClient };