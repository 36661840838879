import React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Card, Modal } from 'antd'
import { useTranslation } from 'react-i18next'

import { useComponents } from './hooks/useComponents'
import { ComponentsTable } from './ComponentsTable'
import { ComponentForm } from './forms/ComponentForm'
import { Component } from '../../types'

export const ComponentsPage: React.FC = () => {
  const { t } = useTranslation()
  const { components, loading, refetch } = useComponents()
  const [modalVisible, toggleModal] = React.useState(false)
  const [editComponent, setEditComponent] = React.useState<Component>()

  const handleEditComponent = (component: Component) => {
    setEditComponent(component)
    toggleModal(true)
  }

  const handleCreateComponent = () => {
    setEditComponent(undefined)
    toggleModal(true)
  }

  const handleSaveComplete = () => {
    toggleModal(false)
    refetch()
  }

  return (
    <>
      <Card
        title={t('plans.components.components')}
        extra={
          <Button onClick={handleCreateComponent} icon={<PlusOutlined />}>
            {t('actions.create')}
          </Button>
        }
        loading={loading || !components}
      >
        <ComponentsTable
          components={components || []}
          refetch={refetch}
          onEdit={handleEditComponent}
        />
        <Modal
          open={modalVisible}
          onCancel={() => toggleModal(false)}
          footer={null}
          destroyOnClose
        >
          <ComponentForm
            component={editComponent}
            onComplete={handleSaveComplete}
          />
        </Modal>
      </Card>
    </>
  )
}
