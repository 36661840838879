import { AppError } from "../../types";

const apiErrorCodes = {
  'EMAIL_EXISTS': 'User already exists.',
  'RECORD_INVALID': 'Please enter all required fields',
}

export interface ApiErrorResponse {
  errors: {
    type: keyof typeof apiErrorCodes
  }[]
}

export const getErrorMessage = (errorCode: keyof typeof apiErrorCodes) => {
  return apiErrorCodes[errorCode] || 'Something went wrong';
}

export const localizeApiErrors = (errorsResponse: ApiErrorResponse, setErrors: (errors: AppError[]) => void) => {
  const errors = errorsResponse.errors.map(error => ({
    message: getErrorMessage(error.type)
  }));

  setErrors(errors);
}