import { Button, Form, Modal, Select } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppError, User } from '../../../types'
import { Errors } from '../../../components'
import { UsersApi } from '../../api'
import { ApiErrorResponse, localizeApiErrors } from '../../api/ApiErrors'
import { usePlans } from '../../plans/hooks/usePlans'

interface UserPlansFormData {
  planIds: string[]
}

interface EditUserPlansModalProps {
  user: User
  visible: boolean
  onComplete: () => void
  onCancel: () => void
}

export const EditUserPlansModal: React.FC<EditUserPlansModalProps> = ({
  user,
  visible,
  onComplete,
  onCancel,
}) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<AppError[]>([])
  const { plans } = usePlans()

  const handleError = (response: ApiErrorResponse) =>
    localizeApiErrors(response, setErrors)

  const handleFinish = async (values: UserPlansFormData) => {
    setLoading(true)
    try {
      // eslint-disable-next-line no-debugger
      debugger
      await UsersApi.updateUserPlans(user.id, {
        plans: values.planIds.map((planId) => ({
          id: planId,
        })),
      })
      setLoading(false)
      onComplete()
    } catch (err: any) {
      setLoading(false)
      handleError(err.response.data)
    }
  }

  return (
    <Modal
      title={`${t('actions.edit')}`}
      open={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form<UserPlansFormData>
        name="userPlansForm"
        onFinish={handleFinish}
        layout="vertical"
        form={form}
      >
        <Errors errors={errors} />

        <Form.Item
          label={t('plans.plans')}
          name="planIds"
          initialValue={user.plans?.map((plan) => plan.id)}
          rules={[
            {
              required: true,
              message: t('forms.errors.requiredField') as string,
            },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            options={plans?.map((plan) => ({
              value: plan.id,
              label: plan.name,
            }))}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={loading}
            loading={loading}
          >
            {t('actions.save')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}
