import { useCallback, useEffect, useState } from "react"
import { ROLES } from "../../../constants";
import { Company, User } from "../../../types";
import { CompaniesApi } from "../../api";

export const useCompany = (id: string) => {
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState<Company>();  
  const [users, setUsers] = useState<User[]>([]);
  const [clients, setClients] = useState<User[]>([]);
  const [admins, setAdmins] = useState<User[]>([]);

  const fetchCompany = useCallback(async () => {
    const data = await CompaniesApi.getCompany(id)
    setCompany(data);
  }, [id])

  const fetchUsers = async () => {
    const data = await CompaniesApi.getUsers(id)
    setUsers(data);
  }

  const fetchClients = async () => {
    const data = await CompaniesApi.getUsers(id, { role: ROLES.client.value })
    setClients(data);
  }

  const fetchAdmins = async () => {
    const data = await CompaniesApi.getUsers(id, { role: ROLES.admin.value })
    setAdmins(data);
  }

  const fetchData = async () => {
    setLoading(true);
    await fetchCompany();
    await fetchUsers();
    await fetchAdmins();
    await fetchClients();
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return {
    company,
    users,
    clients,
    admins,
    loading,
    refetch: fetchCompany,
    refetchUsers: fetchUsers,
    refetchAdmins: fetchAdmins,
    refetchClients: fetchClients,
  }
}