import { Card, Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { CompaniesApi } from '../api'
import { useAuth } from '../auth'
import { EntriesTable } from '../entries'
import { Entry } from '../../types'
import { NewUsersWidget } from './widgets/NewUsersWidget'

export const DashboardPage: React.FC = () => {
  const { user } = useAuth()
  const [entries, setEntries] = useState<Entry[]>()
  const [loading, setLoading] = useState(false)
  const [expiredEntries, setExpiredEntries] = useState<Entry[]>()
  const [expiredLoading, setExpiredLoading] = useState(false)

  const fetchExpiredEntries = async () => {
    setExpiredLoading(true)
    const response = await CompaniesApi.getInvalidEntries(user?.company.id || '')
    setExpiredLoading(false)
    setExpiredEntries(response)
  }

  useEffect(() => {
    if (user && !entries) {
      fetchExpiredEntries()
    }
  }, [user])

  const fetchEntries = async () => {
    setLoading(true)
    const response = await CompaniesApi.getActiveEntries(user?.company.id || '')
    setLoading(false)
    setEntries(response)
  }

  useEffect(() => {
    if (user && !entries) {
      fetchEntries()
    }
  }, [user])

  useEffect(() => {
    const intervalCall = setInterval(() => {
      fetchEntries()
      fetchExpiredEntries()
    }, 1000 * 60)
    return () => {
      clearInterval(intervalCall)
    }
  }, [])

  return (
    <>
      <Row style={{ marginBottom: '16px' }}>
        <Col span={24}>
          <NewUsersWidget />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Card title="Active entries" loading={loading || !entries}>
            <EntriesTable
              entries={entries || []}
              showUser
              showEntryDetails={false}
              showMembership
            />
          </Card>
        </Col>

        <Col span={12}>
          <Card title="Expired entries" loading={expiredLoading || !expiredEntries}>
            <EntriesTable
              entries={expiredEntries || []}
              showUser
              showEntryDetails={false}
              showStatus
            />
          </Card>
        </Col>
      </Row>
      
    </>
  )
}
