import React, { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Card, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { useMemberships } from './hooks/useMemberships'
import { MembershipsTable } from './MembershipsTable'
import { CreateMembershipForm } from './forms'

export const MembershipsPage: React.FC = () => {
  const { t } = useTranslation()
  const { memberships, loading, refetch } = useMemberships()
  const [createModalVisible, toggleCreateModal] = useState(false)

  const handleCreateComplete = () => {
    toggleCreateModal(false)
    refetch()
  }

  return (
    <>
      <Card
        title={t('memberships.memberships')}
        extra={
          <Button
            onClick={() => toggleCreateModal(true)}
            icon={<PlusOutlined />}
          >
            {t('actions.create')}
          </Button>
        }
        loading={loading || !memberships}
      >
        <MembershipsTable memberships={memberships || []} refetch={refetch} />
      </Card>

      <Modal
        open={createModalVisible}
        onCancel={() => toggleCreateModal(false)}
        footer={null}
      >
        <CreateMembershipForm onComplete={handleCreateComplete} />
      </Modal>
    </>
  )
}
