import { useEffect, useState } from 'react'
import { Plan } from '../../../types'
import { PlansApi } from '../../api'

export const usePlans = () => {
  const [loading, setLoading] = useState(false)
  const [plans, setPlans] = useState<Plan[]>()

  const fetchPlans = async () => {
    setLoading(true)
    const data = await PlansApi.getAll()
    setPlans(data)
    setLoading(false)
  }

  useEffect(() => {
    fetchPlans()
  }, [])

  return {
    plans,
    loading,
    refetch: fetchPlans,
  }
}
