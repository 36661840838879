import React, { useState } from 'react'
import {
  Button,
  Form,
  Modal,
  Select,
  Space,
  Table,
  TableColumnType,
  notification,
} from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Membership } from '../../types'
import { useTranslation } from 'react-i18next'
import { UpdateMembershipForm } from './forms'
import { MembershipsApi } from '../api'

type MembershipsTableProps = {
  memberships: Membership[]
  refetch?: () => void
}

export const MembershipsTable: React.FC<MembershipsTableProps> = ({
  memberships,
  refetch,
}) => {
  const { t } = useTranslation()
  const [updateModalVisible, toggleUpdateModal] = useState(false)
  const [deleteModalVisible, toggleDeleteModal] = useState(false)
  const [editItem, setEditItem] = useState<Membership>()
  const [deleteItem, setDeleteItem] = useState<Membership>()
  const [replaceMembershipId, setReplaceMembershipId] = useState<string>()

  const handleEdit = (item: Membership) => () => {
    toggleUpdateModal(true)
    setEditItem(item)
  }

  const handleDelete = (item: Membership) => async () => {
    await MembershipsApi.destroy(item.id, {
      replaceMembershipId: replaceMembershipId || '',
    })
    notification.success({
      message: t('general.deleteSuccess'),
    })
    refetch?.()
  }

  const handleUpdateComplete = () => {
    toggleUpdateModal(false)
    setEditItem(undefined)
    refetch?.()
  }

  const columns: TableColumnType<Membership>[] = [
    {
      title: t('memberships.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('memberships.dailyEntryLimit'),
      dataIndex: 'dailyEntryLimit',
      key: 'dailyEntryLimit',
      render: (dailyEntryLimit: number) =>
        dailyEntryLimit ? dailyEntryLimit : t('memberships.unlimited'),
    },
    {
      title: t('termsAndConditions.termsAndConditions'),
      dataIndex: ['termsAndConditions', 'name'],
      key: 'termsAndConditions',
    },
    {
      title: '',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button
            key="edit"
            icon={<EditOutlined />}
            onClick={handleEdit(record)}
          >
            {t('actions.edit')}
          </Button>
          <Button
            key="destroy"
            icon={<DeleteOutlined />}
            danger
            onClick={() => {
              setDeleteItem(record)
              toggleDeleteModal(true)
            }}
          >
            {t('actions.delete')}
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <>
      <Table dataSource={memberships} columns={columns} rowKey="id" />
      {editItem && (
        <Modal
          open={updateModalVisible}
          onCancel={() => toggleUpdateModal(false)}
          footer={null}
        >
          <UpdateMembershipForm
            membership={editItem}
            onComplete={handleUpdateComplete}
          />
        </Modal>
      )}

      {deleteItem && (
        <Modal
          open={deleteModalVisible}
          onCancel={() => toggleDeleteModal(false)}
          onOk={handleDelete(deleteItem)}
        >
          <Form.Item
            label={t('memberships.replaceMembership')}
            extra={t('memberships.replaceMembershipDescription')}
            name="replaceMembershipId"
            rules={[
              {
                required: true,
                message: t('forms.errors.requiredField') as string,
              },
            ]}
          >
            <Select
              options={memberships
                ?.filter((membership) => membership.id !== deleteItem.id)
                .map((membership) => ({
                  value: membership.id,
                  label: membership.name,
                }))}
              onChange={(value) => setReplaceMembershipId(value)}
            />
          </Form.Item>
        </Modal>
      )}
    </>
  )
}
