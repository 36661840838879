import React, { useEffect } from 'react';
import { Form, Input, Button, Typography } from 'antd';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from './hooks';
import { Errors } from '../../components';
import { AuthApi } from '../api';

interface SignupFormData {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  invite?: string;
  companyInvite?: string;
}

export const SignupPage: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm<SignupFormData>()
  const {handleSignup, errors, loading} = useAuth();
  const [searchParams] = useSearchParams();
  const invite = searchParams.get('invite');
  const companyInvite = searchParams.get('companyInvite');

  const onFinish = (values: SignupFormData) => {
    handleSignup(values);
  };

  const fetchInvite = async () => {
    if (!invite) {
      return false;
    }
    try {
      const userData = await AuthApi.getInvite(invite || '')
      form.setFieldsValue(userData);
    } catch(err) {
      navigate('/');
    }
  }

  useEffect(() => {
    fetchInvite();
  }, [])

  return (
    <Form<SignupFormData>
      name="signup"
      onFinish={onFinish}
      form={form}
    >
      <Typography.Title level={2}>Sign up</Typography.Title>
      <Errors errors={errors} />
      <Form.Item
        name="invite"
        initialValue={invite}
        hidden
      />
      <Form.Item
        name="companyInvite"
        initialValue={companyInvite}
        hidden
      />
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: 'Please input your email',
          },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password',
          },
        ]}
      >
        <Input
          type="password"
          placeholder="Password"
        />
      </Form.Item>

      <Form.Item
        name="firstName"
        rules={[
          {
            required: false,
            message: 'Please input your password',
          },
        ]}
      >
        <Input
          placeholder="First name"
        />
      </Form.Item>

      <Form.Item
        name="lastName"
        rules={[
          {
            required: false,
            message: 'Please input your last name',
          },
        ]}
      >
        <Input
          placeholder="Last name"
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Sign up
        </Button>
      </Form.Item>

      <Typography.Text>
        <Link to="/auth/login">
          Login
        </Link>
      </Typography.Text>
    </Form>
  );
}