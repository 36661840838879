import { useEffect, useState } from "react"
import { Membership } from "../../../types";
import { MembershipsApi } from "../../api";

export const useMemberships = () => {
  const [loading, setLoading] = useState(false);
  const [memberships, setMemberships] = useState<Membership[]>();

  const fetchMemberships = async () => {
    setLoading(true);
    const data = await MembershipsApi.getAll()
    setMemberships(data);
    setLoading(false);
  }

  useEffect(() => {
    fetchMemberships();
  }, [])

  return {
    memberships,
    loading,
    refetch: fetchMemberships
  }
}