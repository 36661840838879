import React from 'react'
import { Button, Popconfirm, Table, TableColumnType, notification } from 'antd'
import { Plan } from '../../types'
import { useTranslation } from 'react-i18next'
import { formatDateTime } from '../helpers'
import { Link } from 'react-router-dom'
import { DeleteOutlined } from '@ant-design/icons'
import { PlansApi } from '../api'

type PlansTableProps = {
  plans: Plan[]
  refetch: () => void
}

export const PlansTable: React.FC<PlansTableProps> = ({ plans, refetch }) => {
  const { t } = useTranslation()

  const handleDeletePlan = async (record: Plan) => {
    await PlansApi.destroy(record.id)
    notification.success({
      message: t('general.deleteSuccess'),
    })
    refetch()
  }

  const columns: TableColumnType<Plan>[] = [
    {
      title: t('general.name'),
      dataIndex: 'name',
      key: 'name',
      render: (name, item) => <Link to={`/plans/${item.id}`}>{name}</Link>,
    },
    {
      title: t('general.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt) => formatDateTime(updatedAt),
    },
    {
      title: t('general.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => formatDateTime(createdAt),
    },
    {
      key: 'actions',
      render: (_, item) => (
        <Popconfirm
          title={`${t('general.areYouSure')}`}
          onConfirm={() => handleDeletePlan(item)}
          okText={`${t('general.yes')}`}
          cancelText={`${t('general.no')}`}
        >
          <Button key="delete" danger icon={<DeleteOutlined />}>
            {t('actions.delete')}
          </Button>
        </Popconfirm>
      ),
    },
  ]

  return (
    <>
      <Table dataSource={plans} columns={columns} rowKey="id" />
    </>
  )
}
