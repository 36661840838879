import React from 'react';
import { Form, Input, Button, Typography } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useAuth } from './hooks';
import { Errors } from '../../components';
import { useTranslation } from 'react-i18next';

interface LoginFormData {
  email: string;
  password: string;
}

export const LoginPage: React.FC = () => {
  const {t} = useTranslation();
  const { handleLogin, errors, loading } = useAuth();
  const onFinish = (values: LoginFormData) => {
    handleLogin(values.email, values.password);
  };

  return (
    <Form<LoginFormData>
      name="login"
      onFinish={onFinish}
    >
      <Typography.Title level={3}>{t('auth.login')}</Typography.Title>
      <Errors errors={errors} />
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: 'Please input your email',
          },
        ]}
      >
        <Input prefix={<UserOutlined />} placeholder={`${t('users.email')}`} />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password',
          },
        ]}
      >
        <Input
          prefix={<LockOutlined />}
          type="password"
          placeholder={`${t('users.password')}`}
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          {t('auth.login')}
        </Button>
      </Form.Item>

      <Typography.Text>
        <Link to="/auth/forgot-password">
          {t('auth.forgotPassword.title')}?
        </Link>
      </Typography.Text>
      <br />
      <Typography.Text>
        {t('auth.signupText')}{' '}
        <Link to="/auth/signup">
          {t('auth.signup')}
        </Link>
      </Typography.Text>
    </Form>
  );
}