import { useEffect, useState } from 'react'
import { User, UserMembership } from '../../../types'
import { UsersApi } from '../../api'

interface UseUsersProps {
  role: User['role']
  searchTerm?: string | null
  userMembershipStatus?: 'all' | UserMembership['status']
  membershipId?: string
}

export const useUsers = ({
  role,
  searchTerm,
  userMembershipStatus = 'all',
  membershipId,
}: UseUsersProps) => {
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState<User[]>()
  const [total, setTotal] = useState(0)

  const fetchUsers = async (page = 1) => {
    setLoading(true)
    const data = await UsersApi.getUsers({
      role,
      searchTerm,
      page,
      userMembershipStatus,
      membershipId,
    })
    setUsers(data.users)
    setTotal(data.total)
    setLoading(false)
  }

  useEffect(() => {
    fetchUsers()
  }, [searchTerm, userMembershipStatus, membershipId])

  return {
    users,
    total,
    loading,
    refetch: fetchUsers,
  }
}
