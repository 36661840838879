import { Component } from '../../types'
import { apiClient } from './ApiClient'

export const getAll: () => Promise<Component[]> = async () => {
  const response = await apiClient.get<Component[]>('/components')
  return response.data
}

export const get: (id: string) => Promise<Component> = async (id) => {
  const response = await apiClient.get<Component>(`/components/${id}`)
  return response.data
}

export const create: (data: {
  component: Omit<Component, 'id'>
}) => Promise<Component> = async (data) => {
  const response = await apiClient.post<Component>('/components', data)
  return response.data
}

export const update: (
  id: string,
  data: {
    component: Component
  }
) => Promise<Component> = async (id, data) => {
  const response = await apiClient.put<Component>(`/components/${id}`, data)
  return response.data
}

export const destroy = async (id: string) => {
  const response = await apiClient.delete<Component>(`/components/${id}`)
  return response.data
}
