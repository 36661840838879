import { User } from "../../types";
import { apiClient } from "./ApiClient";

interface TokenResponseData {
  idToken: string
  refreshToken: string
}

interface ReturnData {
  authToken: string
  refreshToken: string
}

interface SignupMethodParams {companyInvite?: string, invite?: string, email: string, password: string, firstName: string, lastName: string}

type SignupMethod = (params: SignupMethodParams) => Promise<ReturnData>

export const signup: SignupMethod = async (signupParams) => {
  const response = await apiClient.post<TokenResponseData>('/auth/signup', signupParams);

  return {
    authToken: response.data.idToken,
    refreshToken: response.data.refreshToken,
  };
}

export const login = async (email: string, password: string): Promise<ReturnData> => {
  const response = await apiClient.post<TokenResponseData>('/auth/login', {
    email,
    password
  });

  return {
    authToken: response.data.idToken,
    refreshToken: response.data.refreshToken,
  };
}

export const refreshToken = async (refreshToken: string): Promise<ReturnData> => {
  const response = await apiClient.post<ReturnData>('/auth/refresh', {
    'refreshToken': refreshToken
  });

  return response.data;
}

export const logout = async () => {
  await apiClient.post<TokenResponseData>('/auth/logout');
}

export const initiateResetPassword = async (email: string): Promise<void> => {
  await apiClient.post('/auth/initiate-reset-password', {
    email,
  });
}

export const getInvite = async (invite: string): Promise<User> => {
  const response = await apiClient.get('/auth/invite', {
    params: {
      invite
    },
  });

  return response.data;
}