import React, { useState } from 'react'
import { Input, Button, Form, Upload } from 'antd'
import { CompaniesApi } from '../../api'
import { AppError, Company } from '../../../types'
import { PlusOutlined } from '@ant-design/icons'
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from 'antd/es/upload'
import { ApiErrorResponse, localizeApiErrors } from '../../api/ApiErrors'
import { Errors } from '../../../components'

interface UpdateCompanyFormProps {
  company: Company
  onComplete: () => void
}

interface UpdateCompanyFormData {
  id: string
  name: string
  address: string
  logo: UploadChangeParam
  code: string
}

export const UpdateCompanyForm: React.FC<UpdateCompanyFormProps> = ({
  onComplete,
  company,
}) => {
  const [loading, setLoading] = useState(false)
  const [fileList, setFileList] = useState<UploadFile[]>(
    company.logoUrl
      ? [
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: company.logoUrl,
          },
        ]
      : []
  )
  const [errors, setErrors] = useState<AppError[]>([])

  const handleError = (response: ApiErrorResponse) =>
    localizeApiErrors(response, setErrors)

  const handleLogoChange: UploadProps['onChange'] = ({
    fileList: newFileList,
  }) => setFileList(newFileList)

  const handleFinish = async (values: UpdateCompanyFormData) => {
    setLoading(true)
    try {
      const formData = new FormData()
      values.logo?.fileList.forEach((file) => {
        formData.append('company[logo]', file.originFileObj as RcFile)
      })

      if (values.logo?.fileList.length === 0) {
        formData.append('company[logo]', 'null')
      }

      Object.keys(values).forEach((valueKey) => {
        if (valueKey !== 'logo' && valueKey !== 'id') {
          formData.append(
            `company[${valueKey}]`,
            values[valueKey as keyof Omit<UpdateCompanyFormData, 'logo'>]
          )
        }
      })
      await CompaniesApi.updateCompany(values.id, formData)
      onComplete()
    } catch (err: any) {
      setLoading(false)
      handleError(err.response?.data)
    }
  }
  return (
    <Form<UpdateCompanyFormData>
      name="createCompanyForm"
      onFinish={handleFinish}
      layout="vertical"
    >
      <Errors errors={errors} />
      <Form.Item name="id" initialValue={company.id} hidden={true} />

      <Form.Item label="Logo" name="logo">
        <Upload
          maxCount={1}
          fileList={fileList}
          onChange={handleLogoChange}
          listType="picture-card"
        >
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        </Upload>
      </Form.Item>

      <Form.Item
        label="Name"
        name="name"
        initialValue={company.name}
        rules={[{ required: true, message: 'Please input company name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Address"
        name="address"
        initialValue={company.address}
        rules={[{ required: true, message: 'Please input company address' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Code"
        name="code"
        initialValue={company.code}
        rules={[{ required: true, message: 'Please input company code' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={loading}
          loading={loading}
        >
          Update
        </Button>
      </Form.Item>
    </Form>
  )
}
