import { Modal } from 'antd'
import React from 'react'
import { UserMembershipForm } from './UserMembershipForm'
import { useTranslation } from 'react-i18next'
import { User } from '../../../types'

interface EditUserMembershipModalProps {
  user: User
  visible: boolean
  onComplete: () => void
  onCancel: () => void
}

export const EditUserMembershipModal: React.FC<
  EditUserMembershipModalProps
> = ({ user, visible, onComplete, onCancel }) => {
  const { t } = useTranslation()
  return (
    <Modal
      title={`${t('actions.edit')}`}
      open={visible}
      onCancel={onCancel}
      footer={null}
    >
      <UserMembershipForm onComplete={onComplete} user={user} />
    </Modal>
  )
}
