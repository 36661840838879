import { Plan } from '../../types'
import { apiClient } from './ApiClient'

export const getAll: () => Promise<Plan[]> = async () => {
  const response = await apiClient.get<Plan[]>('/plans')
  return response.data
}

export const get: (id: string) => Promise<Plan> = async (id) => {
  const response = await apiClient.get<Plan>(`/plans/${id}`)
  return response.data
}

export const create: (data: {
  plan: Omit<Plan, 'id'>
}) => Promise<Plan> = async (data) => {
  const response = await apiClient.post<Plan>('/plans', data)
  return response.data
}

export const update: (
  id: string,
  data: { plan: Omit<Plan, 'id'> }
) => Promise<Plan> = async (id, data) => {
  const response = await apiClient.put<Plan>(`/plans/${id}`, data)
  return response.data
}

export const destroy = async (id: string) => {
  const response = await apiClient.delete<Plan>(`/plans/${id}`)
  return response.data
}
