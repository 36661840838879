import React, { useState } from 'react'
import { Button, Form, Input } from 'antd'
import { ComponentsApi } from '../../api'
import { ApiErrorResponse, localizeApiErrors } from '../../api/ApiErrors'
import { useTranslation } from 'react-i18next'
import { AppError, Component } from '../../../types'
import { Errors } from '../../../components'

interface ComponentFormData {
  id: string
  name: string
  description?: string
  videoUrl?: string
}

interface ComponentFormProps {
  component?: Component
  onComplete: () => void
}

export const ComponentForm: React.FC<ComponentFormProps> = ({
  component,
  onComplete,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<AppError[]>([])

  const handleError = (response: ApiErrorResponse) =>
    localizeApiErrors(response, setErrors)

  const handleFinish = async (values: ComponentFormData) => {
    setLoading(true)
    try {
      if (component?.id) {
        await ComponentsApi.update(component.id, {
          component: values,
        })
      } else {
        await ComponentsApi.create({
          component: values,
        })
      }
      setLoading(false)
      onComplete()
    } catch (err: any) {
      setLoading(false)
      handleError(err.response.data)
    }
  }

  return (
    <Form<ComponentFormData>
      name="ComponentForm"
      onFinish={handleFinish}
      layout="vertical"
    >
      <Errors errors={errors} />

      <Form.Item
        label={t('general.name')}
        name="name"
        initialValue={component?.name}
        rules={[
          {
            required: true,
            message: t('forms.errors.requiredField') as string,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={`${t('general.description')}`}
        name="description"
        initialValue={component?.description}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={`${t('plans.components.videoUrl')}`}
        name="videoUrl"
        initialValue={component?.videoUrl}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={loading}
          loading={loading}
        >
          {t('actions.save')}
        </Button>
      </Form.Item>
    </Form>
  )
}
