import React, { useEffect, useState } from 'react'
import { Button, Card, Popconfirm, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { TermsAndConditionsForm } from './forms'
import { TermsAndConditionsApi } from '../api'
import { useNavigate, useParams } from 'react-router-dom'
import { TermsAndConditions } from '../../types'
import { DeleteOutlined } from '@ant-design/icons'

export const EditTermsAndConditionsPage: React.FC = () => {
  const navigate = useNavigate()
  const { id = '' } = useParams()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [termsAndConditions, setTermsAndConditions] =
    useState<TermsAndConditions>()

  const fetchTermsAndConditions = async () => {
    setLoading(true)
    const data = await TermsAndConditionsApi.get(id)
    setTermsAndConditions(data)
    setLoading(false)
  }

  const handleDeleteTermsAndConditions = async (record: TermsAndConditions) => {
    await TermsAndConditionsApi.destroy(record.id)
    notification.success({
      message: t('general.deleteSuccess'),
    })
    navigate('/termsAndConditions')
  }

  useEffect(() => {
    fetchTermsAndConditions()
  }, [])

  return (
    <>
      <Card
        title={t('termsAndConditions.termsAndConditions')}
        loading={loading || !termsAndConditions}
        extra={[
          <Popconfirm
            title={`${t('general.areYouSure')}`}
            onConfirm={() =>
              termsAndConditions &&
              handleDeleteTermsAndConditions(termsAndConditions)
            }
            okText={`${t('general.yes')}`}
            cancelText={`${t('general.no')}`}
          >
            <Button key="delete" danger icon={<DeleteOutlined />}>
              {t('actions.delete')}
            </Button>
          </Popconfirm>,
        ]}
      >
        <TermsAndConditionsForm
          termsAndConditions={termsAndConditions}
          onComplete={fetchTermsAndConditions}
        />
      </Card>
    </>
  )
}
