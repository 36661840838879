import { User } from "../types";

export const ROLES: {[key: string]: { value: User['role'], name: string}} = {
  client: {
    value: 'client',
    name: 'Client'
  },
  admin: {
    value: 'admin',
    name: 'Admin'
  },
  superAdmin: {
    value: 'super_admin',
    name: 'Super Admin'
  }
}