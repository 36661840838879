import React, { useState } from 'react'
import { Button, Form, DatePicker, Select } from 'antd'
import { UsersApi } from '../../api'
import { ApiErrorResponse, localizeApiErrors } from '../../api/ApiErrors'
import { useTranslation } from 'react-i18next'
import { AppError, User } from '../../../types'
import { Errors } from '../../../components'
import { useMemberships } from '../../memberships/hooks'
import dayjs, { Dayjs } from 'dayjs'

interface UserMembershipFormData {
  membershipId: string
  startDate: Date
  endDate: Date
}

interface UserMembershipFormProps {
  user: User
  onComplete: () => void
}

export const UserMembershipForm: React.FC<UserMembershipFormProps> = ({
  user,
  onComplete,
}) => {
  const { t } = useTranslation()
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<AppError[]>([])
  const {memberships} = useMemberships();

  const handleError = (response: ApiErrorResponse) =>
    localizeApiErrors(response, setErrors)

  const handleFinish = async (values: UserMembershipFormData) => {
    setLoading(true)
    try {
      if (user.userMembership) {
        await UsersApi.updateUserMembership(user.id, {
          userMembership: values,
        })
      } else {
        await UsersApi.createUserMembership(user.id, {
          userMembership: values,
        })
      }
      setLoading(false)
      onComplete()
    } catch (err: any) {
      setLoading(false)
      handleError(err.response.data)
    }
  }

  const handleStartDateChange = (date: Dayjs | null) => {
    if (!date) {
      return;
    }

    const endDate = date.add(1, 'month')
    form.setFieldValue('endDate', endDate)
  }

  return (
    <Form<UserMembershipFormData>
      name="createUserForm"
      onFinish={handleFinish}
      layout="vertical"
      form={form}
    >
      <Errors errors={errors} />

      <Form.Item
        label={t('memberships.membership')}
        name="membershipId"
        initialValue={
          user.userMembership?.membership.id
        }
        rules={[
          {
            required: true,
            message: t('forms.errors.requiredField') as string,
          },
        ]}
      >
        <Select
          options={memberships?.map((membership) => ({ value: membership.id, label: membership.name }))}
        />
      </Form.Item>

      <Form.Item
        label={t('memberships.startDate')}
        name="startDate"
        initialValue={
          user.userMembership?.startDate
            ? dayjs(user.userMembership?.startDate)
            : dayjs()
        }
        rules={[
          {
            required: true,
            message: t('forms.errors.requiredField') as string,
          },
        ]}
      >
        <DatePicker format="DD.MM.YYYY." onChange={handleStartDateChange} />
      </Form.Item>

      <Form.Item
        label={t('memberships.endDate')}
        name="endDate"
        initialValue={
          user.userMembership?.endDate
            ? dayjs(user.userMembership?.endDate)
            : dayjs().add(1, 'month')
        }
        rules={[
          {
            required: true,
            message: t('forms.errors.requiredField') as string,
          },
        ]}
      >
        <DatePicker format="DD.MM.YYYY." />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={loading}
          loading={loading}
        >
          {user.userMembership ? t('actions.save') : t('actions.create')}
        </Button>
      </Form.Item>
    </Form>
  )
}
