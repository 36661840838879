import React, { useMemo, useState } from 'react'
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Select,
  Space,
  notification,
} from 'antd'
import { PlansApi } from '../../api'
import { ApiErrorResponse, localizeApiErrors } from '../../api/ApiErrors'
import { useTranslation } from 'react-i18next'
import { AppError, Plan } from '../../../types'
import { Errors, TextEditor } from '../../../components'
import { useComponents } from '../hooks/useComponents'
import { CloseOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

type PlanFormData = Omit<Plan, 'id'>

interface PlanFormProps {
  plan?: Plan
}

export const PlanForm: React.FC<PlanFormProps> = ({ plan }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [form] = Form.useForm<PlanFormData>()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<AppError[]>([])
  const { components } = useComponents()

  const [api, contextHolder] = notification.useNotification()

  const openSuccessNotification = () => {
    api.success({
      message: t('general.saveSuccess'),
      duration: 5,
    })
  }

  const componentsOptions = useMemo(() => {
    return components?.map((component) => ({
      label: component.name,
      value: component.id,
    }))
  }, [components])

  const handleError = (response: ApiErrorResponse) =>
    localizeApiErrors(response, setErrors)

  const handleFinish = async (values: PlanFormData) => {
    setLoading(true)
    let newPlan
    try {
      if (plan?.id) {
        newPlan = await PlansApi.update(plan.id, {
          plan: values,
        })
      } else {
        newPlan = await PlansApi.create({
          plan: values,
        })
      }
      setLoading(false)
      navigate(`/plans/${newPlan.id}`)
      openSuccessNotification()
    } catch (err: any) {
      setLoading(false)
      handleError(err.response?.data)
    }
  }

  return (
    <Form<PlanFormData>
      name="planForm"
      onFinish={handleFinish}
      layout="vertical"
      form={form}
      initialValues={plan}
    >
      {contextHolder}
      <Errors errors={errors} />

      <Form.Item
        label={t('general.name')}
        name="name"
        initialValue={plan?.name}
        rules={[
          {
            required: true,
            message: t('forms.errors.requiredField') as string,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('general.description')}
        name="description"
        initialValue={plan?.description}
      >
        <TextEditor theme="snow" />
      </Form.Item>

      <h3>Trainings</h3>

      <Form.List name="trainings">
        {(fields, { add, remove }) => (
          <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
            {fields.map((field) => (
              <Card
                size="small"
                title={`Training ${field.name + 1}`}
                key={field.key}
                extra={
                  <CloseOutlined
                    onClick={() => {
                      remove(field.name)
                    }}
                  />
                }
              >
                <Form.Item label="Name" name={[field.name, 'name']}>
                  <Input />
                </Form.Item>

                <Form.Item
                  label={t('general.description')}
                  name={[field.name, 'description']}
                >
                  <TextEditor theme="snow" />
                </Form.Item>

                {/* Nest Form.List */}
                <Form.Item label={t('plans.components.components')}>
                  <Form.List name={[field.name, 'trainingComponents']}>
                    {(subFields, subOpt) => (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: 0,
                        }}
                      >
                        {subFields.map((subField) => (
                          <Space key={subField.key}>
                            <Form.Item
                              label={t('plans.components.component')}
                              name={[subField.name, 'componentId']}
                              style={{ width: '200px' }}
                            >
                              <Select options={componentsOptions} />
                            </Form.Item>
                            <Form.Item
                              label={t('plans.trainingComponents.series')}
                              name={[subField.name, 'series']}
                            >
                              <Input type="number" />
                            </Form.Item>
                            <Form.Item
                              label={t('plans.trainingComponents.repetitions')}
                              name={[subField.name, 'repetitions']}
                            >
                              <Input type="number" />
                            </Form.Item>
                            <Form.Item
                              label={t('plans.trainingComponents.duration')}
                              name={[subField.name, 'duration']}
                            >
                              <Input />
                            </Form.Item>
                            <CloseOutlined
                              onClick={() => {
                                subOpt.remove(subField.name)
                              }}
                            />
                          </Space>
                        ))}
                        <Button
                          type="dashed"
                          onClick={() => subOpt.add()}
                          block
                        >
                          + {t('plans.trainingComponents.add')}
                        </Button>
                      </div>
                    )}
                  </Form.List>
                </Form.Item>
              </Card>
            ))}

            <Button type="dashed" onClick={() => add()} block>
              + {t('plans.trainings.add')}
            </Button>
          </div>
        )}
      </Form.List>

      {/* {trainings.map((training, index) => (
        <div key={training.id || index}>
          <Row gutter={10}>
            <Col md={20}>
              <Form.Item
                label={t('general.name')}
                name={`trainings[${index}].name`}
                initialValue={training.name}
                rules={[
                  {
                    required: true,
                    message: t('forms.errors.requiredField') as string,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={4} style={{ textAlign: 'right' }}>
              <Button
                onClick={() => {
                  handleRemoveTraining(index)
                }}
                danger
                style={{ marginTop: '30px' }}
                icon={<DeleteOutlined />}
              >
                {t('actions.delete')}
              </Button>
            </Col>
          </Row>
          <Form.Item
            label={t('general.description')}
            name={`trainings[${index}].description`}
            initialValue={training.description}
          >
            <TextEditor theme="snow" />
          </Form.Item>

          <h4>Training components</h4>
          {training.trainingComponents.map((trainingComponent, tcIndex) => (
            <Row
              gutter={10}
              key={trainingComponent.id || `${index}-${tcIndex}`}
            >
              <Col md={6} xs={24}>
                <Form.Item
                  label={t('plans.components.component')}
                  name={`trainings[${index}].trainingComponents[${tcIndex}].componentId`}
                  initialValue={trainingComponent.componentId}
                >
                  <Select options={componentsOptions} />
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <Form.Item
                  label={t('plans.series')}
                  name={`trainings[${index}].trainingComponents[${tcIndex}].series`}
                  initialValue={trainingComponent.series}
                >
                  <Input type="number" />
                </Form.Item>
              </Col>
              <Col md={4} xs={24}>
                <Form.Item
                  label={t('plans.repetitions')}
                  name={`trainings[${index}].trainingComponents[${tcIndex}].repetitions`}
                  initialValue={trainingComponent.repetitions}
                >
                  <Input type="number" />
                </Form.Item>
              </Col>
              <Col md={4} xs={24}>
                <Form.Item
                  label={t('plans.duration')}
                  name={`trainings[${index}].trainingComponents[${tcIndex}].duration`}
                  initialValue={trainingComponent.duration}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={4} style={{ textAlign: 'right' }}>
                <Button
                  onClick={() => {
                    handleRemoveTrainingComponent(index, tcIndex)
                  }}
                  danger
                  icon={<DeleteOutlined />}
                  style={{ marginTop: '30px' }}
                >
                  {t('actions.delete')}
                </Button>
              </Col>
            </Row>
          ))}
          <Divider />
          <Button
            onClick={() => {
              trainings[index].trainingComponents = [
                ...trainings[index].trainingComponents,
                {},
              ]
              setTrainings([...trainings])
            }}
          >
            {t('plans.addTrainingComponent')}
          </Button>
        </div>
      ))} */}

      {/* <Divider />

      <Button onClick={handleAddTraining}>{t('plans.addTraining')}</Button> */}

      <Divider />

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={loading}
          loading={loading}
        >
          {t('actions.save')}
        </Button>
      </Form.Item>
    </Form>
  )
}
