import { Membership } from '../../types'
import { apiClient } from './ApiClient'

export const getAll: () => Promise<Membership[]> = async () => {
  const response = await apiClient.get<Membership[]>('/memberships')
  return response.data
}

export const get: (id: string) => Promise<Membership> = async (id) => {
  const response = await apiClient.get<Membership>(`/memberships/${id}`)
  return response.data
}

export const create: (data: {
  membership: Omit<Membership, 'id' | 'termsAndConditions'>
}) => Promise<Membership> = async (data) => {
  const response = await apiClient.post<Membership>('/memberships', data)
  return response.data
}

export const update: (
  id: string,
  data: { membership: Omit<Membership, 'id' | 'termsAndConditions'> }
) => Promise<Membership> = async (id, data) => {
  const response = await apiClient.put<Membership>(`/memberships/${id}`, data)
  return response.data
}

export const destroy = async (
  id: string,
  data: { replaceMembershipId: string }
) => {
  const response = await apiClient.delete<Membership>(
    `/memberships/${id}?replaceMembershipId=${data.replaceMembershipId}`
  )
  return response.data
}
