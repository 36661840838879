import React, { useMemo, useState } from 'react'
import { Button, Checkbox, Form, Input, Select } from 'antd'
import { TermsAndConditionsApi } from '../../api'
import { ApiErrorResponse, localizeApiErrors } from '../../api/ApiErrors'
import { useTranslation } from 'react-i18next'
import { AppError, TermsAndConditions } from '../../../types'
import { Errors, TextEditor } from '../../../components'
import { useMemberships } from '../../memberships/hooks'
import { useForm } from 'antd/es/form/Form'

interface TermsAndConditionsFormData {
  id: string
  name: string
  content: string
  membershipIds: string[]
  isSignupTermsAndConditions?: boolean
}

interface TermsAndConditionsFormProps {
  termsAndConditions?: TermsAndConditions
  onComplete: () => void
}

export const TermsAndConditionsForm: React.FC<TermsAndConditionsFormProps> = ({
  termsAndConditions,
  onComplete,
}) => {
  const { t } = useTranslation()
  const [checked, setChecked] = useState(
    termsAndConditions &&
      termsAndConditions?.id ===
        termsAndConditions?.company?.signupTermsAndConditionsId
  )
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<AppError[]>([])
  const { memberships } = useMemberships()

  const [form] = useForm<TermsAndConditionsFormData>()

  const membershipOptions = useMemo(() => {
    return memberships?.map((membership) => ({
      label: membership.name,
      value: membership.id,
    }))
  }, [memberships])

  const handleError = (response: ApiErrorResponse) =>
    localizeApiErrors(response, setErrors)

  const handleFinish = async (values: TermsAndConditionsFormData) => {
    setLoading(true)
    if (values.isSignupTermsAndConditions) {
      values.membershipIds = []
    }
    try {
      if (!termsAndConditions?.id) {
        await TermsAndConditionsApi.create({
          termsAndConditions: values,
        })
      } else {
        await TermsAndConditionsApi.update(termsAndConditions.id, {
          termsAndConditions: values,
        })
      }
      setLoading(false)
      onComplete()
    } catch (err: any) {
      setLoading(false)
      handleError(err.response.data)
    }
  }

  return (
    <Form
      name="updateTermsAndConditionsForm"
      form={form}
      onFinish={handleFinish}
      layout="vertical"
    >
      <Errors errors={errors} />

      {termsAndConditions && (
        <Form.Item
          name="id"
          initialValue={termsAndConditions.id}
          hidden={true}
        />
      )}

      <Form.Item
        label={t('general.name')}
        name="name"
        initialValue={termsAndConditions?.name}
        rules={[
          {
            required: true,
            message: t('forms.errors.requiredField') as string,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={`${t('termsAndConditions.content')}`}
        name="content"
        initialValue={termsAndConditions?.content}
        rules={[
          {
            required: true,
            message: t('forms.errors.requiredField') as string,
          },
        ]}
      >
        <TextEditor theme="snow" />
      </Form.Item>

      <Form.Item
        label={`${t('termsAndConditions.signupTermsAndConditions')}`}
        name="isSignupTermsAndConditions"
        valuePropName="checked"
        initialValue={
          termsAndConditions &&
          termsAndConditions?.id ===
            termsAndConditions?.company?.signupTermsAndConditionsId
        }
      >
        <Checkbox
          onChange={(event) => {
            setChecked(event.target.checked)
          }}
        />
      </Form.Item>

      <Form.Item
        label={`${t('memberships.memberships')}`}
        name="membershipIds"
        initialValue={termsAndConditions?.memberships.map(
          (membership) => membership.id
        )}
      >
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder={t('memberships.memberships')}
          options={membershipOptions}
          disabled={checked}
        />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={loading}
          loading={loading}
        >
          {t('actions.save')}
        </Button>
      </Form.Item>
    </Form>
  )
}
