import React from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import { Box } from "..";
import logo from "../../assets/logo.png";

const { Content, Footer } = Layout;

export const AuthLayout: React.FC = () => {
  return (
    <Layout className="layout" style={{ height: "100vh" }}>
      <Content
        style={{
          height: "100%",
          padding: "16px",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <Box width="400px" height="auto">
          <img src={logo} alt="GymAdmin" style={{ maxWidth: "100%" }} />
          <Outlet />
        </Box>
      </Content>
      <Footer style={{ textAlign: "center" }}>GymAdmin</Footer>
    </Layout>
  );
};
