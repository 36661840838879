import { TermsAndConditions } from "../../types";
import { apiClient } from "./ApiClient";

interface TermsAndConditionsPayload {
  termsAndConditions: Omit<TermsAndConditions, 'id' | 'createdAt' | 'updatedAt' | 'memberships'> & { membershipIds: string[], isSignupTermsAndConditions?: boolean }
}

export const getAll: () => Promise<TermsAndConditions[]> = async () => {
  const response = await apiClient.get<TermsAndConditions[]>('/terms_and_conditions');
  return response.data;
}

export const get: (id: string) => Promise<TermsAndConditions> = async (id) => {
  const response = await apiClient.get<TermsAndConditions>(`/terms_and_conditions/${id}`);
  return response.data;
}

export const create: (data: TermsAndConditionsPayload) => Promise<TermsAndConditions> = async (data) => {
  const response = await apiClient.post<TermsAndConditions>('/terms_and_conditions', data);
  return response.data;
}

export const update: (id: string, data: TermsAndConditionsPayload) => Promise<TermsAndConditions> = async (id, data) => {
  const response = await apiClient.put<TermsAndConditions>(`/terms_and_conditions/${id}`, data);
  return response.data;
}

export const destroy = async(id: string) => {
  const response = await apiClient.delete<TermsAndConditions>(`/terms_and_conditions/${id}`);
  return response.data;
}
