import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Descriptions, Modal, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useCompany } from './hooks';
import { UsersTable, CreateUserForm } from '../users';
import { ROLES } from '../../constants';
import { theme } from '../../theme';

export const CompanyDetailsPage: React.FC = () => {
  const {t} = useTranslation();
  const {id} = useParams<{id: string}>();
  const {company, clients, admins, loading, refetchAdmins, refetchClients} = useCompany(id || '');
  const [createUserModalVisible, toggleCreateUserModal] = useState(false);

  const handleCreateComplete = () => {
    toggleCreateUserModal(false);
    refetchAdmins();
    refetchClients();
  }

  if (!company || loading) {
    return (<Card loading={true} />)
  }

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card
        title={company.name}
      >
        <div style={{ marginBottom: theme.spacing.padding }}>{company.logoUrl ? (<img src={company.logoUrl} alt={company.name} style={{ maxWidth: '300px' }} />) : null}</div>
        <Descriptions bordered>
          <Descriptions.Item label={t('companies.address')}>{company.address}</Descriptions.Item>
          <Descriptions.Item label={t('companies.code')}>{company.code}</Descriptions.Item>
        </Descriptions>
      </Card>

      <Card
        title={`${t('users.admins')}(${admins.length})`}
        extra={<Button onClick={() => toggleCreateUserModal(true)} icon={<PlusOutlined />}>{t('users.createAdmin')}</Button>}
      >
        <UsersTable users={admins} showActions hideStatus hideMembership onChange={() => {console.log('blabla')}} />
      </Card>

      <Card
        title={`${t('users.clients')}(${clients.length})`}
      >
        <UsersTable users={clients} showRole showActions onChange={() => {console.log('blabla')}} />
      </Card>

      <Modal title={`${t('users.createAdmin')}`} open={createUserModalVisible} onCancel={() => toggleCreateUserModal(false)} footer={null}>
        <CreateUserForm companyId={company.id} onComplete={handleCreateComplete} role={ROLES.admin.value}/>
      </Modal>
    </Space>
  )
}