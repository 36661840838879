import React, { useEffect, useState } from 'react'
import { Avatar, Button, Card, Table, TableColumnType } from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Link } from 'react-router-dom'

import {
  EditUserMembershipModal,
  UserMembershipStatus,
} from '../../userMemberships'
import { User } from '../../../types'
import { UsersApi } from '../../api'
import { EditOutlined } from '@ant-design/icons'

export const NewUsersWidget: React.FC = () => {
  const { t } = useTranslation()
  const [users, setUsers] = useState<User[]>()
  const [initialLoading, setInitialLoading] = useState(false)
  const [editUser, setEditUser] = useState<User>()
  const [userMembershipModalVisible, toggleUserMembershipModal] =
    useState(false)

  const fetchNewUsers = async () => {
    const response = await UsersApi.getNewUsers()
    setInitialLoading(false)
    const activeUsers = response.filter(
      (u) => u.userMembership?.status === 'active'
    )
    const inactiveUsers = response.filter(
      (u) => !(u.userMembership?.status === 'active')
    )
    setUsers([...inactiveUsers, ...activeUsers])
  }

  const handleUserMembershipComplete = () => {
    toggleUserMembershipModal(false)
    fetchNewUsers()
  }

  const handleEditMembership = (user: User) => () => {
    setEditUser(user)
    toggleUserMembershipModal(true)
  }

  const columns: TableColumnType<User>[] = [
    {
      title: t('users.avatar'),
      dataIndex: 'avatarUrl',
      key: 'avatar',
      render: (value, record) =>
        value ? (
          <img src={value} alt={record.email} style={{ maxHeight: '64px' }} />
        ) : (
          <Avatar>
            {record.name
              .split(' ')
              .map((n) => n[0])
              .join('')}
          </Avatar>
        ),
    },
    {
      title: t('users.name'),
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => (
        <Link to={`/users/${record.id}`}>{value}</Link>
      ),
    },
    {
      title: t('users.email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('users.dob'),
      dataIndex: 'dob',
      key: 'dob',
      render: (value) => (value ? moment(value).format('DD.MM.YYYY.') : null),
    },
    {
      title: t('memberships.status'),
      dataIndex: ['userMembership'],
      key: 'status',
      render: (value) => (
        <UserMembershipStatus status={value?.status} endDate={value?.endDate} />
      ),
    },
    {
      key: 'actions',
      render: (_, record) => (
        <Button
          key="editMembership"
          icon={<EditOutlined />}
          onClick={handleEditMembership(record)}
        >
          Assign membership
        </Button>
      ),
    },
  ]

  useEffect(() => {
    if (!users) {
      setInitialLoading(true)
      fetchNewUsers()
    }
  }, [users])

  useEffect(() => {
    const intervalCall = setInterval(() => {
      fetchNewUsers()
    }, 1000 * 30)
    return () => {
      clearInterval(intervalCall)
    }
  }, [])

  return (
    <Card title="New users" loading={initialLoading}>
      <Table<User>
        rowKey="id"
        columns={columns}
        dataSource={users || []}
        pagination={{ pageSize: 5 }}
      />

      {editUser && (
        <EditUserMembershipModal
          user={editUser}
          visible={userMembershipModalVisible}
          onComplete={handleUserMembershipComplete}
          onCancel={() => toggleUserMembershipModal(false)}
        />
      )}
    </Card>
  )
}
