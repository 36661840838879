import { useEffect, useState } from 'react'
import { Entry, User } from '../../../types'
import { UsersApi } from '../../api'

export const useUser = (id: string) => {
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState<User>()
  const [entries, setEntries] = useState<Entry[]>([])

  const fetchUser = async () => {
    setLoading(true)
    const data = await UsersApi.getUser(id)
    setUser(data)
    setLoading(false)
  }

  const fetchEntries = async () => {
    setLoading(true)
    const data = await UsersApi.getUserEntries(id)
    setEntries(data)
    setLoading(false)
  }

  const fetchData = async () => {
    setLoading(true)
    await fetchUser()
    await fetchEntries()
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return {
    user,
    entries,
    loading,
    refetch: fetchUser,
  }
}
