import React, { useState } from 'react'
import { Input, Button, Form, Upload } from 'antd'
import { CompaniesApi } from '../../api';
import { PlusOutlined } from '@ant-design/icons';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { ApiErrorResponse, localizeApiErrors } from '../../api/ApiErrors';
import { useTranslation } from 'react-i18next';
import { Errors } from '../../../components';
import { AppError } from '../../types';

interface CreateCompanyFormData {
  name: string;
  address: string;
  logo: UploadChangeParam;
  code: string;
  adminEmail: string;
  adminPhone: string;
  adminFirstName: string;
  adminLastName: string;
}

interface CreateCompanyFormProps {
  onComplete: () => void;
}

export const CreateCompanyForm: React.FC<CreateCompanyFormProps> = ({onComplete}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<AppError[]>([]);

  const handleError = (response: ApiErrorResponse) => localizeApiErrors(response, setErrors);

  const handleFinish = async (values: CreateCompanyFormData) => {
    setLoading(true);
    try {
      const formData = new FormData();
      values.logo?.fileList.forEach((file) => {
        formData.append('company[logo]', file.originFileObj as RcFile);
      });

      Object.keys(values).forEach((valueKey) => {
        if (valueKey !== 'logo') {
          formData.append(valueKey, values[valueKey as keyof Omit<CreateCompanyFormData, 'logo'>])
        }
      })

      await CompaniesApi.createCompany(formData);
      setLoading(false);
      onComplete();
    } catch(err: any) {
      setLoading(false);
      console.log(err);
      const response = err.response?.data || { errors: [{ type: 'ERROR' }] };
      handleError(response)
    }
  }

  return (
    <Form<CreateCompanyFormData>
      name="createCompanyForm"
      onFinish={handleFinish}
      layout="vertical"
    >
      <Errors errors={errors} />
      <Form.Item
        label="Logo"
        name="logo"
      >
        <Upload
          maxCount={1}
          listType="picture-card"
        >
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        </Upload>
      </Form.Item>

      <Form.Item
        label="Name"
        name="company[name]"
        rules={[{ required: true, message: 'Please input company name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Address"
        name="company[address]"
        rules={[{ required: true, message: 'Please input company address' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Code"
        name="company[code]"
        rules={[{ required: true, message: 'Please input company code' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>
          {t('actions.create', 'Create')}
        </Button>
      </Form.Item>
    </Form>
  )
}