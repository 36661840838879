import React from 'react'
import { Avatar, Table, TableColumnType } from 'antd'
import { Entry } from '../../types'
import { formatDate, formatDateTime } from '../helpers'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { UserMembershipStatus } from '../userMemberships'
import { useTranslation } from 'react-i18next'

type EntriesTableProps = {
  entries: Entry[]
  loading?: boolean
  showUser?: boolean
  showMembership?: boolean
  showEntryDetails?: boolean
  showStatus?: boolean
}

export const EntriesTable: React.FC<EntriesTableProps> = ({
  entries,
  loading,
  showUser,
  showMembership,
  showEntryDetails = true,
  showStatus,
}) => {
  const { t } = useTranslation()
  let columns: TableColumnType<Entry>[] = [
    {
      title: 'Start',
      dataIndex: 'start',
      key: 'start',
      render: (value) => (value ? formatDateTime(value) : null),
    },
  ]

  if (showEntryDetails) {
    columns = [
      ...columns,
      {
        title: 'End',
        dataIndex: 'end',
        key: 'end',
        render: (value) => (value ? formatDateTime(value) : null),
      },
      {
        title: 'Duration',
        key: 'end',
        render: (_, record) =>
          record.end && record.start
            ? moment(record.end).diff(moment(record.start), 'minutes')
            : null,
      },
    ]
  }

  if (showStatus) {
    columns = [
      ...columns,
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (value, record) => (
          <span style={{ color: record.status === 'valid' ? 'green' : 'red' }}>
            {value}{' '}
            {record.status === 'invalid' ? ` (${record.statusMessage})` : null}
          </span>
        ),
      },
    ]
  }

  if (showUser) {
    columns = [
      {
        title: 'Avatar',
        dataIndex: ['user', 'avatarUrl'],
        key: 'avatar',
        render: (value, record) =>
          value ? (
            <img
              src={value}
              alt={record.user.email}
              style={{ maxHeight: '64px' }}
            />
          ) : (
            <Avatar>
              {record.user.name
                .split(' ')
                .map((n) => n[0])
                .join('')}
            </Avatar>
          ),
      },
      {
        title: 'User',
        dataIndex: ['user', 'name'],
        key: 'user',
        render: (value, record) => (
          <Link to={`/users/${record.user.id}`}>{value}</Link>
        ),
      },
      ...columns,
    ]
  }

  if (showMembership) {
    columns = [
      ...columns,
      {
        title: 'Membership',
        key: 'membership',
        render: (_, record) => {
          const userMembership = record.user.userMembership
          if (!userMembership) return null
          return (
            <>
              <UserMembershipStatus
                status={userMembership.status}
                endDate={userMembership.endDate}
              />{' '}
              {t('memberships.expires')}: {formatDate(userMembership.endDate)}
            </>
          )
        },
      },
    ]
  }

  return (
    <Table
      dataSource={entries}
      columns={columns}
      rowKey="id"
      loading={loading}
      pagination={{ showSizeChanger: true }}
    />
  )
}

export default EntriesTable
