import React, { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Card, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { useTermsAndConditions } from './hooks'
import { TermsAndConditionsTable } from './TermsAndConditionsTable'
import { TermsAndConditionsForm } from './forms'

export const TermsAndConditionsPage: React.FC = () => {
  const { t } = useTranslation()
  const { termsAndConditions, loading, refetch } = useTermsAndConditions()
  const [createModalVisible, toggleCreateModal] = useState(false)

  const handleCreateComplete = () => {
    toggleCreateModal(false)
    refetch()
  }

  return (
    <>
      <Card
        title={t('termsAndConditions.termsAndConditions')}
        extra={
          <Button
            onClick={() => toggleCreateModal(true)}
            icon={<PlusOutlined />}
          >
            {t('actions.create')}
          </Button>
        }
        loading={loading || !termsAndConditions}
      >
        <TermsAndConditionsTable
          termsAndConditions={termsAndConditions || []}
          refetch={refetch}
        />
      </Card>

      <Modal
        open={createModalVisible}
        onCancel={() => toggleCreateModal(false)}
        footer={null}
      >
        <TermsAndConditionsForm onComplete={handleCreateComplete} />
      </Modal>
    </>
  )
}
