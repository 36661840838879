import React, { useState } from "react";
import { Input, Button, Form, DatePicker } from "antd";
import { UsersApi } from "../../api";
import { ApiErrorResponse, localizeApiErrors } from "../../api/ApiErrors";
import { useTranslation } from "react-i18next";
import { AppError, User } from "../../../types";
import { Errors } from "../../../components";

interface CreateUserFormData {
  firstName: string;
  lastName: string;
  email: string;
  role: User['role'];
  phone?: string;
  companyId?: string;
  dob: Date;
}

interface CreateUserFormProps {
  companyId?: string;
  role: User['role'];
  onComplete: () => void;
  showPhone?: boolean;
  showPassword?: boolean
}

export const CreateUserForm: React.FC<CreateUserFormProps> = ({
  onComplete,
  companyId,
  role,
  showPhone,
  showPassword = true
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<AppError[]>([]);

  const handleError = (response: ApiErrorResponse) =>
    localizeApiErrors(response, setErrors);

  const handleFinish = async (values: CreateUserFormData) => {
    setLoading(true);
    try {
      await UsersApi.create({
        user: values,
      });
      setLoading(false);
      onComplete();
    } catch (err: any) {
      setLoading(false);
      handleError(err.response.data);
    }
  };

  return (
    <Form<CreateUserFormData>
      name="createUserForm"
      onFinish={handleFinish}
      onFinishFailed={(err) => console.log(err)}
      layout="vertical"
    >
      <Errors errors={errors} />

      <Form.Item name="companyId" initialValue={companyId} hidden={true} />

      <Form.Item
        label={t("users.email", "Email")}
        name="email"
        rules={[
          {
            required: true,
            message: t(
              "users.form.errors.email",
              "Please input user email"
            ) as string,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t("users.password", "Password")}
        name="password"
        hidden={!showPassword}
        rules={[
          {
            required: showPassword,
            message: t(
              "users.form.errors.password",
              "Please input user password"
            ) as string,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t("users.phone", "Phone")}
        name="phone"
        hidden={!showPhone}
        rules={[
          {
            required: showPhone,
            message: t(
              "users.form.errors.phone",
              "Please input user phone"
            ) as string,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t("users.firstName", "First name")}
        name="firstName"
        rules={[
          {
            required: true,
            message: t(
              "users.form.errors.firstName",
              "Please input user first name"
            ) as string,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t("users.lastName", "Last name")}
        name="lastName"
        rules={[
          {
            required: true,
            message: t(
              "users.form.errors.lastName",
              "Please input user last name"
            ) as string,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t("users.dob")}
        name="dob"
        rules={[
          {
            required: true,
            message: t(
              "users.form.errors.dob",
              "Please input user date of birth"
            ) as string,
          },
        ]}
      >
        <DatePicker format="DD.MM.YYYY" />
      </Form.Item>

      <Form.Item
        label={t("users.role", "Role")}
        name="role"
        initialValue={role}
        hidden={true}
      />

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={loading}
          loading={loading}
        >
          {t("actions.create", "Create")}
        </Button>
      </Form.Item>
    </Form>
  );
};
