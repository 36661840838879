import React from 'react';
import { Layout } from 'antd';
import { ChildrenProps } from '../../types';
import { AuthenticatedHeader } from './AuthenticatedHeader';

const { Content, Footer } = Layout;

export const AuthenticatedLayout: React.FC<ChildrenProps> = ({ children }) => {
  return (
    <Layout className="layout" style={{ height: '100vh' }}>
      <AuthenticatedHeader />
      <Content style={{ overflowY: 'scroll', height: '100%', padding: '32px 48px 32px 48px' }}>
        {children}
      </Content>
      {/* <Footer style={{ textAlign: 'center' }}></Footer> */}
    </Layout>
  )
}