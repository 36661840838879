import React, { useMemo, useState } from 'react'
import { Button, Form, Input, Select } from 'antd'
import { MembershipsApi } from '../../api'
import { ApiErrorResponse, localizeApiErrors } from '../../api/ApiErrors'
import { useTranslation } from 'react-i18next'
import { AppError } from '../../../types'
import { Errors } from '../../../components'
import { useTermsAndConditions } from '../../termsAndConditions/hooks'

interface CreateMembershipFormData {
  name: string,
  dailyEntryLimit?: number;
  termsAndConditionsId?: string | null;
}

interface CreateMembershipFormProps {
  onComplete: () => void
}

export const CreateMembershipForm: React.FC<CreateMembershipFormProps> = ({
  onComplete,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<AppError[]>([])
  const {termsAndConditions} = useTermsAndConditions()

  const termsAndConditionsOptions = useMemo(() => {
    return termsAndConditions?.map((termsAndConditions) => ({
      label: termsAndConditions.name,
      value: termsAndConditions.id,
    }))
  }, [termsAndConditions])

  const handleError = (response: ApiErrorResponse) =>
    localizeApiErrors(response, setErrors)

  const handleFinish = async (values: CreateMembershipFormData) => {
    setLoading(true)
    try {
      await MembershipsApi.create({
        membership: values,
      })
      setLoading(false)
      onComplete()
    } catch (err: any) {
      setLoading(false)
      handleError(err.response?.data)
    }
  }

  return (
    <Form<CreateMembershipFormData>
      name="createMembershipForm"
      onFinish={handleFinish}
      layout="vertical"
    >
      <Errors errors={errors} />

      <Form.Item
        label={t('memberships.name')}
        name="name"
        rules={[
          {
            required: true,
            message: t('forms.errors.requiredField') as string,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('memberships.dailyEntryLimit')}
        name="dailyEntryLimit"
      >
        <Input type="number" />
        {t('memberships.dailyEntryLimitHint')}
      </Form.Item>

      <Form.Item
        label={t('termsAndConditions.termsAndConditions')}
        name="termsAndConditionsId"
      >
        <Select allowClear options={termsAndConditionsOptions}/>
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={loading}
          loading={loading}
        >
          {t('actions.create')}
        </Button>
      </Form.Item>
    </Form>
  )
}
