import styled from 'styled-components';
import { theme } from '../theme';

interface BoxProps {
  width?: string;
  height?: string;
}

export const Box = styled.div<BoxProps>`
  background: #fff;
  padding: ${theme.spacing.padding};
  border-radius: ${theme.spacing.padding};
  box-shadow: 0 1px 2px #c9c9c9;
  width: ${({width = '100%'}) => width};
  height: ${({height = '100%'}) => height};
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`